import React, {
    useState,
    useCallback,
  } from 'react'  
import update from 'immutability-helper'
import { addDays, format } from 'date-fns'
import {
    Layout,
    Card,
    FormLayout,
    TextField,
    TextStyle,
    DatePicker,
    Popover,
    Icon,
    Button,
    Subheading
  } from "@shopify/polaris"
import {
    CalendarMajor
  } from '@shopify/polaris-icons';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/3024-day.css';
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/hint/show-hint.css'
const { CSSLint } = require("csslint");
if (typeof window !== 'undefined') {
  window.CSSLint = CSSLint;
}
if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
  require('codemirror/mode/css/css');
  require('codemirror/mode/javascript/javascript');
  require('codemirror/addon/hint/css-hint.js')
  require('codemirror/addon/lint/css-lint.js')
  require('codemirror/addon/lint/lint.js')
}
const customStyle = `
  /* below are all the default CSS styles for the referral pages */
  .upsellplus-app {
    padding-bottom: 4px;
  }
  .upsellplus-container {
    border-style: solid;
    color: #212b36;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    padding: 10px;
    justify-content: center;
    align-items: stretch;
    align-content: center;
  }
  .upsellplus-image-container {
    box-sizing: border-box;
    display: inline-block;
    width: 64px;
    height: 64px;
    border-radius: 5px;
    border: 1px solid #aaa;
    margin-right: 6px;
    background: white;
    align-self: center;
  }
  .upsellplus-image {
    object-fit: cover;
    object-position: center;
    height: 62px;
    width: 62px;
  }
  .upsellplus-title-container {
    box-sizing: border-box;
    display: flex;
    flex: 2;
    flex-direction: column;
    margin: auto;
    min-width: 100px;
    padding: 5px;
    text-align: left;
    padding-right: 12px;
    max-width: 218px;
  }
  .upsellplus-title {
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2px;
    line-height: 18px;
    text-align: left;
  }
  .upsellplus-message {
    font-size: 10px;
  }
  .upsellplus-action-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
  }
  .upsellplus-selection {
    margin-top: 5px;
    padding: 0 3px;
    max-width: 130px;
    border: 1px solid #767676;
    border-radius: 3px;
  }
  .upsellplus-original-price {
    text-decoration: line-through;
    font-weight: 500;
    margin-right: 5px;
  }
  .upsellplus-price {
    box-sizing: border-box;
    color: #212b36;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-align: right;
  }
  .upsellplus-subtitle {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
  .upsellplus-button {
    align-items: center;
    box-shadow: rgba(22,29,37,.05) 0 1px 0 0;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 13.3333px;
    justify-content: center;
    line-height: 1;
    margin: 10px 0 0;
    padding: 5px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background, border, box-shadow;
    user-select: none; 
    border-style: solid;
    float: right;      
  }
  .upsellplus-button-content {
    font-weight: var(--p-button-font-weight, 400);
    line-height: 16px;
    text-transform: initial;
    letter-spacing: initial;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1px;
    min-height: 1px;  
  }
  .upsellplus-button-text {
    font-size: 14px;
    font-weight: 500;
  }
`
function AdvancedTab(props) {
    const { state, setState } = props;
    const [datePickerPopoverVisible, setDatePickerPopoverVisible] = useState(false)
    const [customCssShow, setCustomCssShow] = useState(false)
    const date = new Date()
    const [{month, year}, setDate] = useState({month: date.getMonth(), year: date.getFullYear()});
    const [selectedDates, setSelectedDates] = useState({
        start: date,
        end: addDays(date, 30),
    });
    const handleMonthChange = useCallback(
        (month, year) => setDate({month, year}),
        [],
      );
    const handleOfferRunDateChange = () => {
        setState(update(state, { offerStarting: { $set: selectedDates.start }, offerEnding: { $set: selectedDates.end }}))
    }
    const showCustomCss = () => {
        setCustomCssShow(true)
        if(state.css === ""){
          setState(update(state, { css: { $set: customStyle }}))
        }
      }
    const datePickerActivatorButton = (
        <Button onClick={() => setDatePickerPopoverVisible(true)}>
          <div style={{display:'flex', alignItems: 'center',justifyContent: 'center', gap: '5px'}}>
          <Icon
            source={CalendarMajor}
            color="base" style={{display:'inline-block'}}></Icon>
            { state.offerStarting !== "" && state.offerEnding !== "" ?
              state.offerStarting.seconds &&  state.offerEnding.seconds ?
                <span>{format(new Date(state.offerStarting.seconds * 1000), "yyyy-MM-dd")} - {format(new Date(state.offerEnding.seconds * 1000), "yyyy-MM-dd")}</span>
                :
                <span>{format(state.offerStarting, "yyyy-MM-dd")} - {format(state.offerEnding, "yyyy-MM-dd")}</span>
              :
              <span>Today - Now</span>
              }
          </div>
        </Button>
      );
    return (
        <Card title="Advanced" sectioned>
       
        <Card sectioned>
        <div style={{paddingBottom:'10px'}}>
          <Subheading>Scheduling</Subheading>
        </div>
        <Popover
          active={datePickerPopoverVisible}
          activator={datePickerActivatorButton}
          onClose={()=>setDatePickerPopoverVisible(false)}
          fluidContent
          fullHeight
        >
          <div style={{maxWidth: '600px', padding: '20px'}}>
          <Layout>
            <Layout.Section>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  type="string"
                  label="Starting"
                  onChange={() => {}}
                  autoComplete="off"
                  value={format(selectedDates.start, "yyyy-MM-dd")}
                />
                <TextField
                  type="string"
                  label="Ending"
                  onChange={() => {}}
                  autoComplete="off"
                  value={format(selectedDates.end, "yyyy-MM-dd")}
                />
              </FormLayout.Group>
            </FormLayout>
            </Layout.Section>
            <Layout.Section>
              <DatePicker
                month={month}
                year={year}
                onChange={setSelectedDates}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
                multiMonth
                allowRange
              />
            </Layout.Section>
            <Layout.Section>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <Button onClick={()=>setDatePickerPopoverVisible(false)}>Cancel</Button>
                <Button primary onClick={()=>{
                  handleOfferRunDateChange();
                  setDatePickerPopoverVisible(false)
                  }}>Apply</Button>
              </div>
            </Layout.Section>
          </Layout>
          </div>
        </Popover>
        <div style={{paddingTop: '10px'}}>
          <TextStyle variation="subdued">Schedule an offer for the future. We tried the past, but our time travel API has some bugs.</TextStyle>
        </div>
        </Card>
        
      </Card>
    )
}

export default AdvancedTab;